import AmbienteTile from '../ambiente-tile/AmbienteTile'
import './style.css'

import work from '../../assets/img/vectors/work.svg'

// CTCW
import ctcw01 from '../../assets/img/fotos-do-ambiente/ctcw/01.jpg'
import ctcw02 from '../../assets/img/fotos-do-ambiente/ctcw/02.jpg'
import ctcw03 from '../../assets/img/fotos-do-ambiente/ctcw/03.jpeg'
import ctcw04 from '../../assets/img/fotos-do-ambiente/ctcw/04.jpeg'


// auditorio
import auditorio01 from '../../assets/img/fotos-do-ambiente/auditorio/01.webp'
import auditorio02 from '../../assets/img/fotos-do-ambiente/auditorio/02.webp'
import auditorio03 from '../../assets/img/fotos-do-ambiente/auditorio/03.jpeg'


// area de convivencia
import areaConvivencia01 from '../../assets/img/fotos-do-ambiente/area-de-convivencia/01.webp'
import areaConvivencia02 from '../../assets/img/fotos-do-ambiente/area-de-convivencia/02.webp'
import areaConvivencia03 from '../../assets/img/fotos-do-ambiente/area-de-convivencia/03.webp'


// escritorio compartilhado
import escritorioCompartilhado01 from '../../assets/img/fotos-do-ambiente/escritorio-compartilhado/01.jpeg'
import escritorioCompartilhado02 from '../../assets/img/fotos-do-ambiente/escritorio-compartilhado/02.jpeg'
import escritorioCompartilhado03 from '../../assets/img/fotos-do-ambiente/escritorio-compartilhado/03.jpeg'


// espaco de atendimento
import espacoAtendimento01 from '../../assets/img/fotos-do-ambiente/espaco-de-atendimento/01.jpeg'
import espacoAtendimento02 from '../../assets/img/fotos-do-ambiente/espaco-de-atendimento/02.jpeg'
import espacoAtendimento03 from '../../assets/img/fotos-do-ambiente/espaco-de-atendimento/03.jpeg'


// sala aristoteles
import aristoteles01 from '../../assets/img/fotos-do-ambiente/sala-aristoteles/01.jpeg'
import aristoteles02 from '../../assets/img/fotos-do-ambiente/sala-aristoteles/02.jpeg'
import aristoteles03 from '../../assets/img/fotos-do-ambiente/sala-aristoteles/03.jpeg'


// sala drucker
import drucker01 from '../../assets/img/fotos-do-ambiente/sala-drucker/01.jpeg'
import drucker02 from '../../assets/img/fotos-do-ambiente/sala-drucker/02.jpeg'
import drucker03 from '../../assets/img/fotos-do-ambiente/sala-drucker/03.jpeg'


// sala epicteto
import epicteto01 from '../../assets/img/fotos-do-ambiente/sala-epicteto/01.jpeg'
import epicteto02 from '../../assets/img/fotos-do-ambiente/sala-epicteto/02.jpeg'
import epicteto03 from '../../assets/img/fotos-do-ambiente/sala-epicteto/03.jpeg'


// sala freud
import freud01 from '../../assets/img/fotos-do-ambiente/sala-freud/01.jpeg'
import freud02 from '../../assets/img/fotos-do-ambiente/sala-freud/02.jpeg'
import freud03 from '../../assets/img/fotos-do-ambiente/sala-freud/03.jpeg'


// sala platao
import platao01 from '../../assets/img/fotos-do-ambiente/sala-platao/01.jpeg'
import platao02 from '../../assets/img/fotos-do-ambiente/sala-platao/02.jpeg'
import platao03 from '../../assets/img/fotos-do-ambiente/sala-platao/03.jpg'
import platao04 from '../../assets/img/fotos-do-ambiente/sala-platao/04.jpeg'


// sala seneca
import seneca01 from '../../assets/img/fotos-do-ambiente/sala-seneca/01.jpeg'
import seneca02 from '../../assets/img/fotos-do-ambiente/sala-seneca/02.jpeg'
import seneca03 from '../../assets/img/fotos-do-ambiente/sala-seneca/03.jpeg'


// sala socrates
import socrates01 from '../../assets/img/fotos-do-ambiente/sala-socrates/01.jpeg'
import socrates02 from '../../assets/img/fotos-do-ambiente/sala-socrates/02.jpg'
import socrates03 from '../../assets/img/fotos-do-ambiente/sala-socrates/03.jpeg'


// salas de reunioes
import salaReuniao01 from '../../assets/img/fotos-do-ambiente/salas-de-reunioes/01.jpeg'
import salaReuniao02 from '../../assets/img/fotos-do-ambiente/salas-de-reunioes/02.jpeg'
import salaReuniao03 from '../../assets/img/fotos-do-ambiente/salas-de-reunioes/03.jpeg'
import salaReuniao04 from '../../assets/img/fotos-do-ambiente/salas-de-reunioes/04.jpeg'

function Ambientes() {

    const ambientes = [
        {
            title: 'CTCW',
            pictures: [ctcw01, ctcw02, ctcw03, ctcw04]
        },
        {
            title: 'Auditório',
            pictures: [auditorio01, auditorio02, auditorio03]
        },
        {
            title: 'Área de Convivência',
            pictures: [areaConvivencia01, areaConvivencia02, areaConvivencia03]
        },
        {
            title: 'Escritório Compartilhado',
            pictures: [escritorioCompartilhado01, escritorioCompartilhado02, escritorioCompartilhado03]
        },
        {
            title: 'Espaço de Atendimento',
            pictures: [espacoAtendimento01, espacoAtendimento02, espacoAtendimento03]
        },
        {
            title: 'Sala Aristóteles',
            pictures: [aristoteles01, aristoteles02, aristoteles03]
        },
        {
            title: 'Sala Drucker',
            pictures: [drucker01, drucker02, drucker03]
        },
        {
            title: 'Sala Epicteto',
            pictures: [epicteto01, epicteto02, epicteto03]
        },
        {
            title: 'Sala Freud',
            pictures: [freud01, freud02, freud03]
        },
        {
            title: 'Sala Platão',
            pictures: [platao01, platao02, platao03, platao04]
        },
        {
            title: 'Sala Sêneca',
            pictures: [seneca01, seneca02, seneca03]
        },
        {
            title: 'Sala Sócrates',
            pictures: [socrates01, socrates02, socrates03]
        },
        {
            title: 'Salas de Reuniões',
            pictures: [salaReuniao01, salaReuniao02, salaReuniao03, salaReuniao04]
        },
    ]

    return (
        <div id="ambientes">

            {ambientes.map((ambiente, index) => <AmbienteTile key={index} item={ambiente} />)}

            <div className="pict">
                <img src={work} alt="Work"/>
            </div>

        </div>
    )
}

export default Ambientes