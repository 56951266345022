import React, { Component } from 'react'
import { getArticles, getArticlesByCategoryId } from '../../services/ArticleService'
import TopArticlesTile from '../top-article-tile/TopArticleTile'

import './style.css'

class SuggestedArticles extends Component {
    constructor() {
        super()
        this.state = {
            articles: []
        }
    }

    async componentDidMount() {
        const { category } = this.props
        let articles = []

        if (category) {
            articles = await getArticlesByCategoryId(category)
        }

        let qtd = articles.length
       
        let random = Math.floor(Math.random() * (qtd - 3))
        
        if (qtd < 3) {
            const articles2 = await getArticles()
            articles = articles.concat(articles2)
        }
        
        qtd = articles.length
        
        random = Math.floor(Math.random() * (qtd - 3))

        this.setState({ articles: [articles[random], articles[random + 1], articles[random + 2]] })
    }

    render() {
        if (this.state.articles.length === 0) {
            return <div></div>
        }
        return (
            <div className="ta sa">
                <p className="suggest">Artigos sugeridos</p>
                {this.state.articles.map(article => {
                    return (
                        <React.Fragment key={article.id}>
                            <TopArticlesTile article={article} />
                        </React.Fragment>
                    )
                })}
            </div>
        )
    }
}

export default SuggestedArticles