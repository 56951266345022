import React, { Component } from 'react'
import './style.css'
import menuIcon from '../../assets/img/icons/menu.svg'
import closeIcon from '../../assets/img/icons/close.svg'

class MenuMobile extends Component {
    constructor() {
        super()
        this.state = {
            menu: 'hide',
            icon: menuIcon
        }
    }

    toggleDropdown = (param) => {
        const p = this.state[param]
        this.setState({
            [param]: p ? '' : 'hide',
            icon: p ? closeIcon : menuIcon
        })
    }

    toggleMenu = (param) => {
        this.toggleDropdown(param)
    }

    render() {
        return (
            <div className="menu-mobile">
                <button className="btn-mobile" onClick={() => this.toggleMenu('menu')}>
                    <img className="btn-icon" src={this.state.icon} alt="Menu Mobile" />
                </button>
                <div className={`shadow ${this.state.menu}`}>
                    <div className="menu">
                        <a className="menu-item" href="#section2">Ciatos Coworking</a>
                        <a className="menu-item" href="#section3">Onde estamos?</a>
                        <a className="menu-item" href="#section4">Serviços</a>
                        <a className="menu-item" href="#section5">Facilities</a>
                        <a className="menu-item" href="#section1">Planos</a>
                        <a className="menu-item" href="#section1">Espaço</a>
                        <a className="menu-item" target="_blank" rel="noreferrer" href="http://blog.grupociatos.com.br">Blog</a>
                    </div>
                </div>
            </div>
        )
    }
}

export default MenuMobile