import React, { Component } from 'react'
import Footer from '../../components/footer/Footer'
import Header from '../../components/header/Header'
import WelcomeSection from '../../components/welcome-section/WelcomeSection'
import ArticleList from '../../components/article-list/ArticleList'
import './style.css'
import { getSegmentById } from '../../services/SegmentService'
import WhatsappLink from '../../../components/whatsapp-link/WhatsappLink'
import FeaturedLinks from '../../../components/featured-links/FeaturedLinks'

class Segment extends Component {
    constructor() {
        super()
        this.state = {
            segmentName: 'Segmentos'
        }
    }

    async componentDidMount() {
        const segment = await getSegmentById(this.props.match.params.id)
        if (segment) {
            this.setState({
                segmentName: segment.name
            })
        }
    }

    render() {
        return (
            <div id="segment-page" className="category-page">
                <FeaturedLinks />
                <WhatsappLink />
                <Header />
                <WelcomeSection title={this.state.segmentName} />
                <section className="main-section">
                    <ArticleList segmentId={this.props.match.params.id} />
                </section>
                <Footer />
            </div>
        )
    }
}

export default Segment