import { Component } from "react"
import './style.css'
import FeaturedLinks from "../../components/featured-links/FeaturedLinks"
import Footer from "../../components/footer/Footer"
import Header from "../../components/header/Header"
import PrivacyPolicy from "../../components/privacy-policy/PrivacyPolicy"
import Section10 from "../../components/section10/Section10"
import WhatsappLink from '../../components/whatsapp-link/WhatsappLink'
import UseTerms from "../../components/use-terms/UseTerms"

import pvv from '../../assets/img/pvv.png'

class CulturaCiatos extends Component {
    constructor() {
        super()

        this.state = {
            useTermsDisplayControl: 'hide',
            privacyPolicyDisplayControl: 'hide',
        }

        this.showModal = this.showModal.bind(this)
        this.hideModal = this.hideModal.bind(this)
    }

    showModal(param) {
        this.setState({ [param]: '' })
    }

    hideModal(param) {
        this.setState({ [param]: 'hide' })
    }

    render() {
        return (
            <main id="cultura-ciatos-page">
                <FeaturedLinks />
                <WhatsappLink />
                <div className={`${this.state.useTermsDisplayControl}`}>
                    <UseTerms close={this.hideModal} />
                </div>
                <div className={`${this.state.privacyPolicyDisplayControl}`}>
                    <PrivacyPolicy close={this.hideModal} />
                </div>

                <Header />

                <div className="cultura">
                    <img src={pvv} alt="Propósito, visão e valores"/>
                </div>

                <Section10 />

                <Footer />
            </main>
        )
    }
}

export default CulturaCiatos