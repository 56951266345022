import React from 'react'
import './style.css'

function WelcomeSection(props) {
    return (
        <div className="welcome">
            <div className="shadow">
                <div className="content">
                    <h1 className="title">{props.title ?? 'Conteúdo para pequenos e médios empreendedores'}</h1>
                    <p className="subtitle">Aumente a eficiência da sua empresa com os ensinamentos do nosso blog</p>
                </div>
            </div>
        </div>
    )
}

export default WelcomeSection