import React, { Component } from 'react'

import './style.css'

import logo from '../../assets/img/logo-ciatos-coworking.png'
import MenuMobile from '../menu-mobile/MenuMobile'

class Header extends Component {

    constructor() {
        super()
        this.state = {
            background: 'background-transparent',
        }
    }

    async componentDidMount() {
        window.addEventListener('scroll', this.listenToScroll)
    }

    listenToScroll = () => {
        const windowScrollPosition = window.pageYOffset

        if (windowScrollPosition > 100) {
            this.setState({
                background: 'background-white',
            })
        } else {
            this.setState({
                background: 'background-transparent',
            })
        }
    }

    render() {
        return (
            <header id="header" className={`header ${this.state.background}`}>
                <div className="header-content">
                    <a href=".">
                        <img className="logo" src={logo} alt="Logo do Ciatos Coworking" />
                    </a>

                    <nav className="navbar">
                        <a className="nav-item" href="/#ciatos-coworking">Ciatos Coworking</a>
                        <a className="nav-item" href="/#onde-estamos-localizados">Onde estamos?</a>
                        <a className="nav-item" href="/#servicos-da-ciatos-coworking">Serviços</a>
                        <a className="nav-item" href="/#facilities">Facilities</a>
                        <a className="nav-item" href="/#planos-e-precos-da-ciatos-coworking">Planos</a>
                        <a className="nav-item" href="/#conheca-nossos-espacos">Espaço</a>
                        <a className="nav-item" target="_blank" rel="noreferrer" href="/blog">Blog</a>
                    </nav>

                    <MenuMobile segments={this.state.segments} categories={this.state.categories} />
                </div>
            </header>
        )
    }
}

export default Header