import React from 'react'
import ArticleInfo from '../article-info/ArticleInfo'
import './style.css'

function Article(props) {

    // article data
    const { title, imgUrl, content, author, date, authorAvatar } = props.article
    const innerHtml = {
        __html: content
    }

    // conditional rendering for img
    const img = imgUrl ? (
        <div className="image-wrapper">
            <img src={imgUrl} alt="Featured Media of article" />
        </div>
    ) : ''

    return (
        <div className="article">
            <div className="content">
                <h1 className="title">{title}</h1>
                <ArticleInfo info={{
                    author: author,
                    authorAvatar: authorAvatar,
                    date: date,
                }} />
                {img}
                <div className="body" dangerouslySetInnerHTML={innerHtml}></div>
            </div>
        </div>
    )
}

export default Article