import './style.css'

import iconClose from '../../assets/img/icons/close-white.svg'

function PrivacyPolicy(props) {

    return (
        <div id="privacy-policy" className="privacy-policy">
            <div className="shadow">
                <div className="content">

                    <button className="btn-close" onClick={() => props.close('privacyPolicyDisplayControl')}>
                        <img src={iconClose} alt="Fechar" />
                    </button>

                    <div className="header">
                        <p>Política de Privacidade</p>
                    </div>

                    <div className="body">
                        <p>Esta Política e suas respectivas atualizações devem prevalecer sobre todas as políticas, propostas, contratos,
                        entendimentos anteriores e acordos, orais ou escritos, eventualmente existentes entre as partes versando, direta
                        ou indiretamente, sobre o tema de privacidade.</p>

                        <p>As soluções contidas no site grupociatos.com.br (ou simplesmente “Plataforma”) são disponibilizadas
                        pelo Grupo Ciatos Reestruturações Empresariais, neste ato representada pela Ciatos Soluções, pessoa jurídica
                        de direito privado, inscrita no CNPJ/MF sob o nº 14.777.996/0001-50, sediada em Rua Guaicuí, 715, sala 2 -
                        Coração de Jesus, Belo Horizonte - MG, Brasil.</p>

                        <h2>A. Introdução</h2>

                        <ol>
                            <li>A privacidade dos visitantes do nosso site é muito importante para nós, e estamos comprometidos em protegê-la.
                            Esta política explica o que faremos com suas informações pessoais.</li>
                            <li>Consentir com o uso de cookies de acordo com os termos desta política, quando você acessa nosso site pela
                            primeira vez nos permite usar cookies toda vez que você acessar nosso site.</li>
                        </ol>

                        <h2>B. Coleta de informações pessoais</h2>

                        <p>Os seguintes tipos de informações pessoais podem ser coletados, armazenados e usados:</p>

                        <ol>
                            <li>informações sobre o seu computador, incluindo seu endereço IP, localização geográfica, tipo e versão do
                            navegador e sistema operacional;</li>
                            <li>informações sobre suas visitas e uso deste site, incluindo fonte de referência, duração da visita,
                            visualizações de página e caminhos de navegação no site;</li>
                            <li>informações, como seu endereço de e-mail, que você digita quando se registra em nosso site;</li>
                            <li>informações que você digita ao criar um perfil em nosso site – por exemplo, seu nome, fotos de perfil,
                            sexo, data de nascimento, status de relacionamento, interesses e hobbies, informações educacionais e de emprego;</li>
                            <li>informações, como seu nome e endereço de e-mail, que você digita para configurar assinaturas de nossos
                            e-mails e/ou newsletters;</li>
                            <li>informações que você digita durante o uso dos serviços em nosso site;</li>
                            <li>informações geradas ao usar nosso site, incluindo quando, com que frequência e em que circunstâncias
                            você o utiliza;</li>
                            <li>informações relacionadas a tudo o que você compra, serviços que usa ou transações que realiza através do
                            nosso site, incluindo nome, endereço, número de telefone, endereço de e-mail e dados do cartão de crédito;</li>
                            <li>informações que você publica em nosso site com a intenção de publicá-las na internet, incluindo seu nome de
                            usuário, fotos de perfil e o conteúdo de suas publicações;</li>
                            <li>informações contidas em quaisquer comunicações que você nos envia por e-mail ou através de nosso site,
                            incluindo o conteúdo e os metadados da comunicação;</li>
                            <li>qualquer outra informação pessoal que você nos enviar.</li>
                        </ol>

                        <p>Antes de nos divulgar informações pessoais de outra pessoa, você deve obter o consentimento dessa pessoa para
                        a divulgação e o processamento dessas informações pessoais de acordo com esta política.</p>

                        <h2>C. Uso de suas informações pessoais</h2>

                        <p>As informações pessoais que nos são enviadas por meio de nosso site serão usadas para os fins especificados
                        nesta política ou nas páginas relevantes do site. Podemos usar suas informações pessoais para o seguinte:</p>

                        <ol>
                            <li>administrar nosso site e nossos negócios;</li>
                            <li>personalizar nosso site para você;</li>
                            <li>possibilitar o uso dos serviços disponíveis em nosso site;</li>
                            <li>enviar produtos adquiridos através do nosso site;</li>
                            <li>prestar serviços adquiridos através do nosso site;</li>
                            <li>enviar extratos, faturas e lembretes de pagamento, bem como coletar seus pagamentos;</li>
                            <li>enviar comunicações comerciais de marketing;</li>
                            <li>enviar notificações por e-mail solicitadas especificamente por você;</li>
                            <li>enviar nossa newsletter por e-mail, caso você a tenha solicitado (você pode nos informar a qualquer momento
                            se não quiser mais receber a newsletter);</li>
                            <li>enviar comunicações de marketing relacionadas aos nossos negócios ou aos negócios de terceiros cuidadosamente
                            selecionados que acreditamos ser do seu interesse, por correio ou, onde você especificamente concordou com
                            isso, por e-mail ou tecnologia semelhante (você pode nos informar a qualquer momento se não mais quiser mais
                            receber comunicações de marketing);</li>
                            <li>fornecer à terceiros informações estatísticas sobre nossos usuários (mas esses terceiros não poderão identificar
                            nenhum usuário individual a partir dessas informações);</li>
                            <li>lidar com perguntas e reclamações feitas por você ou sobre você em relação ao nosso site;</li>
                            <li>manter nosso site seguro e evitar fraudes;</li>
                            <li>verificar a conformidade com os termos e condições que regem o uso do nosso site (incluindo o monitoramento de
                            mensagens privadas enviadas por meio do serviço de mensagens privadas do nosso site); e</li>
                            <li>outros usos.</li>
                        </ol>

                        <p>Se você enviar informações pessoais para publicação em nosso site, publicaremos e usaremos essas informações de acordo
                        com a licença que você nos concedeu.</p>

                        <p>Suas configurações de privacidade podem ser usadas para limitar a publicação de suas informações em nosso site, e
                        ajustadas através do uso de controles de privacidade no site. Sem seu consentimento expresso, não forneceremos
                        suas informações pessoais a terceiros para fins de marketing direto por parte deles ou de terceiros.</p>

                        <h2>D. Divulgação de informações pessoais</h2>

                        <p>Podemos divulgar suas informações pessoais a qualquer um de nossos funcionários, executivos, seguradoras,
                        consultores profissionais, agentes, fornecedores ou subcontratados conforme razoavelmente necessário para
                        os fins estabelecidos nesta política.</p>

                        <p>Podemos divulgar suas informações pessoais a qualquer membro de nosso grupo de empresas (isso significa nossas
                        subsidiárias, nossa holding e todas as suas subsidiárias) conforme razoavelmente necessário para os fins
                        estabelecidos nesta política.</p>

                        <p>Podemos divulgar suas informações pessoais:</p>

                        <ol>
                            <li>na medida em que somos obrigados a fazê-lo por lei;</li>
                            <li>em relação a qualquer processo judicial em andamento ou potencial;</li>
                            <li>para estabelecer, exercer ou defender nossos direitos legais (incluindo fornecer informações a terceiros para
                            fins de prevenção de fraudes e redução do risco de crédito);</li>
                            <li>ao comprador (ou comprador em potencial) de qualquer negócio ou ativo que estejamos vendendo (ou contemplando
                            vender); e</li>
                            <li>a qualquer pessoa que acreditemos razoavelmente que possa solicitar a um tribunal ou outra autoridade
                            competente a divulgação dessas informações pessoais, quando, em nossa opinião razoável, for provável
                            que tal tribunal ou autoridade ordene a divulgação dessas informações pessoais.</li>
                        </ol>

                        <p>Exceto conforme estabelecido nesta política, não forneceremos suas informações pessoais a terceiros.</p>

                        <h2>E. Transferências internacionais de dados</h2>

                        <ol>
                            <li>As informações que coletamos podem ser armazenadas, processadas e transferidas entre qualquer um dos países
                            em que operamos, a fim de nos permitir usar as informações de acordo com esta política.</li>
                            <li>As informações que coletamos podem ser transferidas para os seguintes países que não possuem leis de proteção de dados
                            equivalentes às vigentes no Espaço Econômico Europeu: Estados Unidos da América, Rússia, Japão, China e Índia.</li>
                            <li>As informações pessoais que você publica em nosso site ou envia para publicação em nosso site podem estar
                            disponíveis, através da internet, em todo o mundo. Não podemos impedir o uso ou uso indevido de tais informações
                            por terceiros.</li>
                            <li>Você concorda expressamente com as transferências de informações pessoais descritas nesta seção E.</li>
                        </ol>

                        <h2>F. Retenção de informações pessoais</h2>
                        <ol>
                            <li>Esta seção F define nossas políticas e procedimentos de retenção de dados, projetados para ajudar a garantir
                            o cumprimento de nossas obrigações legais em relação à retenção e exclusão de informações pessoais.</li>
                            <li>As informações pessoais que processamos para qualquer propósito ou propósitos não devem ser mantidas por
                            mais tempo do que o necessário.</li>
                        </ol>

                        <h2>G. Segurança de suas informações pessoais</h2>

                        <ol>
                            <li>Tomaremos as devidas precauções técnicas e organizacionais para evitar a perda, mau uso ou alteração de suas
                            informações pessoais.</li>
                            <li>Armazenaremos todas as suas informações pessoais fornecidas em nossos servidores seguros (protegidos por
                            senha e firewall).</li>
                            <li>Todas as transações financeiras eletrônicas realizadas através do nosso site serão protegidas por tecnologia
                            de criptografia.</li>
                            <li>Você reconhece que a transmissão de informações pela internet é inerentemente insegura e que não podemos
                            garantir a segurança dos dados enviados pela internet.</li>
                            <li>Você é responsável por manter em sigilo a senha usada para acessar nosso site; não solicitaremos sua senha
                            (exceto quando você fizer login no site).</li>
                        </ol>

                        <h2>H. Alterações</h2>

                        <p>Podemos atualizar esta política periodicamente, através da publicação de uma nova versão em nosso site.
                        Você deve verificar esta página ocasionalmente para garantir que compreende quaisquer alterações nesta
                        política. Podemos notificá-lo sobre alterações nesta política por e-mail ou através do sistema de
                        mensagens privados em nosso site.</p>

                        <h2>I. Seus direitos</h2>

                        <p>Você pode nos instruir a fornecer qualquer informação pessoal que detenhamos sobre você; o fornecimento dessas
                        informações estará sujeito ao seguinte:</p>

                        <ol>
                            <li>fornecimento de evidência apropriada de sua identidade (para esse objetivo geralmente aceitaremos foto
                            sua segurando um documento pessoal com foto – CNH; identidade, passaporte).</li>
                        </ol>

                        <p>Podemos reter as informações pessoais solicitadas na extensão permitida por lei.</p>

                        <p>Você pode nos instruir a qualquer momento para não processar suas informações pessoais para fins de marketing.</p>

                        <p>Na prática, você geralmente concordará expressamente, com antecedência, com o uso de suas informações pessoais
                        para fins de marketing, ou ofereceremos a oportunidade de não permitir o uso de suas informações pessoais para
                        fins de marketing.</p>

                        <h2>J. Sites de terceiros</h2>

                        <p>Nosso site inclui links para detalhes de sites de terceiros. Não temos controle sobre e não somos responsáveis
                        pelas políticas e práticas de privacidade de terceiros.</p>

                        <h2>K. Atualização de informações</h2>

                        <p>Informe-nos se as informações pessoais que mantemos sobre você precisam ser corrigidas ou atualizadas.</p>

                        <h2>L. Cookies</h2>

                        <p>Nosso site usa cookies. Um cookie é um arquivo que contém um identificador (uma sequência de letras e números)
                        que é enviado por um servidor da web para um navegador e armazenado pelo navegador. O identificador é então
                        enviado de volta ao servidor toda vez que o navegador solicita uma página do servidor. Os cookies podem ser
                        cookies “persistentes” ou cookies “de sessão”: um cookie persistente será armazenado por um navegador e
                        permanecerá válido até a data de vencimento definida, a menos que seja excluído pelo usuário antes da data
                        de vencimento; um cookie de sessão, por outro lado, expirará no final da sessão do usuário, quando o
                        navegador for fechado. Os cookies normalmente não contêm nenhuma informação que identifique pessoalmente
                        um usuário, mas as informações pessoais que armazenamos sobre você podem estar vinculadas às informações
                        armazenadas e obtidas a partir de cookies. Utilizamos cookies de sessão / cookies persistentes / cookies
                        persistentes e de sessão em nosso site.</p>

                        <ol>
                            <li>Os nomes dos cookies que usamos em nosso site e os propósitos para os quais são usados estão descritos abaixo:
                    <ol>
                                    <li>Cookies necessários para o funcionamento do website: estes cookies são necessários para o funcionamento
                                    do website e não podem ser desativados dos nossos sistemas.</li>
                                    <li>Cookies de desempenho: Estes cookies permitem-nos calcular o número de utilizadores e as fontes
                                    do tráfego para medirmos e melhorar o desempenho do nosso website.</li>
                                    <li>Cookies funcionais: Esses cookies permitem a disponibilização no website de funcionalidades e
                                    personalizações melhoradas.</li>
                                </ol>
                            </li>
                        </ol>

                        <p>O bloqueio de todos os cookies causará um impacto negativo na usabilidade de muitos sites. Se você bloquear
                        os cookies, não poderá usar todos os recursos em nosso site.</p>

                        <h2>M. DADOS DE MENORES DE 12 ANOS</h2>

                        <p><strong>Tratamento de dados pessoais de menores: </strong>Não coletamos ou conscientemente solicitamos dados
                        pessoais de menores de 12 anos. Se tomarmos conhecimento de que coletamos dados pessoais de um menor de 12 anos,
                        cancelaremos a conta criada e excluiremos os dados pessoais, apenas podendo manter dados pessoais que tenham como
                        objetivo evitar nova tentativa de cadastro.</p>

                        <h2>N. DIREITOS COM RELAÇÃO AOS SEUS DADOS PESSOAIS</h2>

                        <p><strong>Direitos e Garantias: </strong>o Usuário tem direitos e garantias em relação aos seus dados pessoais. Nós
                        disponibilizamos mecanismos, detalhados abaixo, para que os Usuários tenham clareza e transparência no exercício de
                        seus direitos. Sempre que necessário, nossa equipe estará pronta para avaliar eventuais solicitações.</p>

                        <p><strong>Direitos Previstos na LGPD: </strong>nos termos da Lei Geral de Proteção de Dados (Lei 13.709/2018 –) (“LGPD”),
                        você tem direito de:</p>

                        <ol>
                            <li>confirmar que realizamos o tratamento dos seus dados pessoais;</li>
                            <li>acessar seus dados pessoais;</li>
                            <li>requerer a correção de dados pessoais que estejam incompletos, inexatos ou desatualizados;</li>
                            <li>requerer a anonimização, o bloqueio ou a eliminação de dados pessoais desnecessários, excessivos ou tratados
                            em desconformidade com o disposto na LGPD;</li>
                            <li>requerer a portabilidade dos seus dados pessoais a outro fornecedor de serviço ou produto, observados os nossos
                            segredos comerciais e industriais, após a regulamentação pela Autoridade Nacional de Proteção de Dados;</li>
                            <li>requerer a eliminação dos dados pessoais tratados com base no seu consentimento, exceto nas hipóteses de
                            conservação de dados pessoais previstas na LGPD;</li>
                            <li>requerer informações sobre com quem compartilhamos os seus dados pessoais;</li>
                            <li>requerer informações sobre a possibilidade de não fornecer o seu consentimento e as consequências;</li>
                            <li>revogar o seu consentimento para o tratamento de seus dados pessoais, quando houver tratamento realizado com
                            base no seu consentimento;</li>
                            <li>manifestar oposição a tratamento que viole a LGPD.</li>
                        </ol>

                        <p><strong>Informações Adicionais: </strong>antes de respondermos a uma solicitação para exercício dos direitos
                        mencionados acima, podemos solicitar que você nos forneça algumas informações para confirmarmos sua identidade.</p>

                        <h2>O. PRÁTICAS DE SPAM</h2>

                        <p><strong>Posicionamento: </strong>Somos contra toda e qualquer prática que promova SPAM de qualquer espécie e
                        temos o compromisso de assegurar que as mensagens enviadas pela Plataforma são sempre de interesse ou importância
                        para os respectivos destinatários. Além disso, a qualquer momento é possível optar por parar de receber e-mails,
                        ajustando suas preferências para recebimento de e-mails ou cancelando sua conta.</p>

                        <p><strong>Orientações particulares: </strong>da mesma forma, cobramos de nossos Usuários que não pratiquem SPAM,
                        que se certifiquem que as mensagens enviadas sejam sempre de interesse do destinatário, estando desde já cientes
                        de que a não observância das boas práticas de uso da Internet poderá gerar o cancelamento da conta do Usuário
                        infrator, conforme definido nos Termos de Uso, bem como a divulgação de dados pessoais de Cadastro, nos termos
                        desta Política de Privacidade.</p>

                        <h2>P. INFORMAÇÕES E DÚVIDAS</h2>

                        <p><strong>Dúvidas sobre a Política ou sobre Seus Dados Pessoais: </strong>Se após a leitura desta Política você tiver
                        quaisquer dúvidas ou quiser apresentar alguma solicitação relativa a seus dados pessoais, você pode entrar em contato
                        com nosso time de privacidade ou com nosso Encarregado(a) por meio deste contato: juridico@grupociatos.com.br -
                        (31) 25316607.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy