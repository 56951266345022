import React from 'react';
import c from './WhatsappLink.module.css';
import zap from '../../assets/img/whatsapp-logo.png';

function WhatsappLink() {
    return (
        <a className={c.wrapper} target="_blank" rel="noreferrer" href="https://api.whatsapp.com/send?phone=+5531991100349&text=ola">
            <img src={zap} alt="Whatsapp" className={c.logo} />
        </a>
    )
}

export default WhatsappLink;