import React, { Component } from 'react'
import './style.css'
import instagram from '../../../assets/img/icons/instagram.svg'
import facebook from '../../../assets/img/icons/facebook.svg'
import twitter from '../../../assets/img/icons/twitter.svg'
import linkedin from '../../../assets/img/icons/linkedin.svg'
import logo from '../../../assets/img/logo-white.png'
import { getCategories } from '../../services/CategoryService'

class Footer extends Component {
    constructor() {
        super()
        this.state = {
            categories: []
        }
    }

    async componentDidMount() {
        const categories = await getCategories()
        this.setState({ categories: categories })
    }

    render() {
        return (
            <div id="blog-footer" className="footer">
                <div className="social-media">
                    <a target="_blank" rel="noreferrer" href="https://www.instagram.com/ciatoscoworking/">
                        <div className="item">
                            <img className="social-icon" src={instagram} alt="Instagram" />
                            <span className="label">Instagram</span>
                        </div>
                    </a>
                    <a target="_blank" rel="noreferrer" href="https://www.facebook.com/CiatosCoworking/">
                        <div className="item">
                            <img className="social-icon" src={facebook} alt="Facebook" />
                            <span className="label">Facebook</span>
                        </div>
                    </a>
                    <a target="_blank" rel="noreferrer" href="https://twitter.com/grupociatos">
                        <div className="item">
                            <img className="social-icon" src={twitter} alt="Twitter" />
                            <span className="label">Twitter</span>
                        </div>
                    </a>
                    <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/grupociatos/">
                        <div className="item">
                            <img className="social-icon" src={linkedin} alt="Linkedin" />
                            <span className="label">LinkedIn</span>
                        </div>
                    </a>
                </div>

                <div className="bottom">
                    <div className="links left-links">
                        <p>CATEGORIAS</p>
                        <a href="/">Mais recentes</a>
                        {
                            this.state.categories.map(c => {
                                return <a key={c.id} href={`/categories/${c.id}`}>{c.name}</a>
                            })
                        }
                    </div>

                    <div className="logo">
                        <img className="logo-img" src={logo} alt="Logo do Grupo Ciatos Branca" />
                    </div>

                    <div className="links right-links">
                        <p>CIATOS COWORKING</p>
                        <a href="/#section2">Sobre a Ciatos Coworking</a>
                        <a href="/#section3">Onde Estamos</a>
                        <a href="/#section4">Nossos Serviços</a>
                        <a href="/#section5">Nossas Facilities</a>
                        <a href="/#section6">Os Planos da Ciatos Coworking</a>
                        <a href="/#section7">Conheça Nossos Espaços</a>
                        <a href="/#section10">Diferenciais e Vantagens</a>
                        {/* <a href="/">Planejamento Familiar</a>
                        <a href="/">Máquina de Vendas</a> */}
                    </div>
                </div>

                <div className="divider"></div>

                <div>
                    <p className="copyright">Copyright © 2020</p>
                </div>
            </div>
        )
    }
}

export default Footer