import React, { Component } from 'react'
import './style.css'

import iconEmail from '../../assets/img/icons/email.svg'
import iconPhone from '../../assets/img/icons/phone.svg'
import iconAddress from '../../assets/img/icons/address.svg'
import iconClose from '../../assets/img/icons/close-white.svg'
import imgArvore from '../../assets/img/arvore-grupo-ciatos-white.png'

import { sendEmailModal } from '../../services/emailService.js'

class Modal extends Component {

    constructor() {
        super()

        this.state = {
            show: '',
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            message: 'Olá, eu gostaria de falar com um consultor sobre...',
            resultContent: '',
            resultColor: '',
        }

        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
    }

    async handleSubmit(event) {
        event.preventDefault()
        const success = await sendEmailModal(
            this.state.firstName,
            this.state.lastName,
            this.state.email,
            this.state.phone,
            this.state.message,
            'Ciatos Coworking',
        )

        if (success) {
            this.setState({
                resultContent: 'Dados enviados com sucesso!',
                resultColor: 'success'
            })
        } else {
            this.setState({
                resultContent: 'Erro ao enviar os dados!',
                resultColor: 'failed'
            })
        }
    }

    handleChange(event) {
        const { name, value } = event.target

        this.setState({ [name]: value })
    }

    render() {
        return (
            // <div id="modal" className="custom-modal" style={{display: 'none'}}>
            <div id="modal" className={`custom-modal}`}>
                <div className="custom-modal-frame frame animate__animated animate__fadeInUp">

                    <div className="form-wrapper">
                        <form id="modal-form" onSubmit={this.handleSubmit}>

                            <div className="horizontal">
                                <div className="field">
                                    <label htmlFor="firstName">Nome</label>
                                    <input id="modalFirstName"
                                        type="text"
                                        name="firstName"
                                        value={this.state.firstName}
                                        onChange={this.handleChange}
                                        placeholder="John" />
                                </div>
                                <div className="field">
                                    <label htmlFor="lastName">Sobrenome</label>
                                    <input id="modalLastName"
                                        type="text"
                                        name="lastName"
                                        value={this.state.lastName}
                                        onChange={this.handleChange}
                                        placeholder="Doe" />
                                </div>
                            </div>

                            <div className="horizontal">
                                <div className="field">
                                    <label htmlFor="email">Email</label>
                                    <input id="modalEmail"
                                        type="email"
                                        name="email"
                                        value={this.state.email}
                                        onChange={this.handleChange}
                                        placeholder="johndoe@email.com" />
                                </div>
                                <div className="field">
                                    <label htmlFor="telefone">Telefone</label>
                                    <input id="modalPhone"
                                        type="text"
                                        name="phone"
                                        value={this.state.phone}
                                        onChange={this.handleChange}
                                        placeholder="(021)-454-545" />
                                </div>
                            </div>

                            <div className="horizontal">
                                <div className="field">
                                    <label htmlFor="message">Como podemos ajudá-lo?</label>
                                    <textarea
                                        name="message"
                                        id="modalMessage"
                                        cols="30"
                                        rows="5"
                                        onChange={this.handleChange}
                                        value={this.state.message}
                                    />
                                </div>
                            </div>

                            <button className="btn-modal" type="submit">ENVIAR</button>
                        </form>
                        <div id="result" className={`${this.state.resultColor}`}>{this.state.resultContent}</div>
                    </div>

                    <div className="info-wrapper">
                        <p className="title">Entrar em contato</p>
                        <div className="info-item email">
                            <div className="img-wrapper"><img src={iconEmail} alt="Email" /></div>
                            <p>atendimento@ctcw.com.br</p>
                        </div>
                        <div className="info-item phone">
                            <div className="img-wrapper"><img src={iconPhone} alt="Telefone" /></div>
                            <p>(31) 4042-0009</p>
                        </div>
                        <div className="info-item address">
                            <div className="img-wrapper"><img src={iconAddress} alt="Endereço" /></div>
                            <p>Belo Horizonte/MG <br /> Rua Guaicuí, 715 - Luxemburgo <br /> (31) 2531-6670</p>
                        </div>
                        <img className="arvore" src={imgArvore} alt="Árvore do Grupo Ciatos" />
                    </div>

                    <button id="close-consultor" className="btn-close" onClick={() => this.props.closeModal('modalDisplayControl')}>
                        <img src={iconClose} alt="Close" />
                    </button>

                </div>
            </div>
        )
    }
}

export default Modal