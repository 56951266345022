import React from 'react'
import noImage from '../../../assets/img/no-image.png'
import './style.css'

function TopArticlesTile(props) {
    const { title, date, author, imgUrl, slug, excerpt } = props.article
    const innerHtml = {
        __html: excerpt
    }
    return (
        <a className="no-text-decoration" href={`/article/${slug}`}>
            <div className="tile">
                <div className="article-img"
                    style={{ backgroundImage: `url(${imgUrl ?? noImage})` }}>
                </div>
                <div className="content">
                    <p className="title">{title}</p>

                    <div className="excerpt" dangerouslySetInnerHTML={innerHtml}></div>

                    <p className="info">{`${author} - ${date}`}</p>
                </div>
            </div>
        </a>
    )
}

export default TopArticlesTile