import { Component } from "react";
import Pictures from "../pictures/Pictures";
import './style.css'

class AmbienteTile extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showPictures: false,
        }
    }

    openPictures = () => {
        this.setState({ showPictures: true })
        document.body.style.overflow = 'hidden'
    }

    closePictures = () => {
        this.setState({ showPictures: false })
        document.body.style.overflow = 'visible'
    }

    render() {
        const { title, pictures } = this.props.item
        const picturesComponent = this.state.showPictures ? <Pictures pictures={pictures} close={this.closePictures} /> : ''
        return (
            <div className="ambiente flex-item">
                <div className="card" onClick={this.openPictures}>
                    <p className="title">{title}</p>
                    <div className="image-wrapper">
                        <img src={pictures[0]} alt="Auditório" />
                    </div>
                </div>
                {picturesComponent}
            </div>
        )
    }
}

export default AmbienteTile