import React from 'react'
import './style.css'

function ArticleInfo(props) {
    const { author, authorAvatar, date } = props.info
    return (
        <div className="author-info">
            <div className="image-wrapper">
                <img src={authorAvatar} alt="Avatar do autor" />
            </div>
            <div className="text">
                <p className="author">{author}</p>
                <p className="date">{date}</p>
            </div>
        </div>
    )
}

export default ArticleInfo
