import React, { Component } from 'react'
import { getArticle } from '../../services/ArticleService.js'
import TopArticlesTile from '../top-article-tile/TopArticleTile'

import './style.css'

class TopArticles extends Component {
    constructor() {
        super()
        this.state = {
            articles: []
        }
    }

    async componentDidMount() {
        const article1 = await getArticle(6)
        const article2 = await getArticle(702)
        const article3 = await getArticle(11741)

        this.setState({ articles: [article1, article2, article3] })
    }

    render() {
        return (
            <div className="ta">
                {this.state.articles.map(article => {
                    return (
                        <React.Fragment key={article.id}>
                            <TopArticlesTile article={article} />
                        </React.Fragment>
                    )
                })}
            </div>
        )
    }
}

export default TopArticles