import './style.css'
import imgMainSection10 from '../../assets/img/section10-main.png'


function Section10(props) {
    return (
        <section id="section10" className="section10">
            <div className="content">
                <h2>DIFERENCIAIS E VANTAGENS</h2>
                <div className="wrapper">
                    <div className="image-wrapper">
                        <img src={imgMainSection10} alt="Duas pessoas em reunião" />
                    </div>
                    <ul>
                        <li>Salas Privativas;</li>
                        <li>Auditórios para palestras, treinamentos e cursos - valores sob consulta;</li>
                        <li>Salas de reunião equipadas;</li>
                        <li>Contratos e horários flexíveis;</li>
                        <li>Localização privilegiada: bancos, restaurantes, correios, Fórum TJMG nas proximidades;</li>
                        <li>Sem burocracias, despesas com aluguel, condomónio e assinaturas de serviços de internet e telefonia, estrutura montada e tecnológica, ambiente profissional, com possibilidade de interação com vários segmentos e ampliação de rede de contatos e negócios, livre das distrações e adaptações do home-office;</li>
                        <li>Segurança.</li>
                    </ul>
                </div>
                <div className="bottom">
                    <p>Estamos de portas abertas para receber você, vamos tomar um café?</p>
                    <button className="btn" onClick={props.showModal}>Falar com um consultor</button>
                </div>
            </div>
        </section>
    )
}

export default Section10