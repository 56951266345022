const API_URL = "https://ciatoscoworking.com.br/wordpress/wp-json/wp/v2/"

export async function getSegmentById(id) {
    return fetch(`${API_URL}segmentos/${id}`)
        .then(res => res.json())
        // .then(data => console.log(data))
        .catch(err => console.error('Error: ', err))
}

export async function getSegments() {
    const url = `${API_URL}segmentos`
    return fetch(url)
        .then(res => res.json())
        .catch(err => console.error('Error: ', err))
}