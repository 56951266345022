import './style.css'

import imgArvoreGrupoCiatos from '../../assets/img/arvore-grupo-ciatos-white.png'
import imgFacebookFooter from '../../assets/img/footer-facebook.png'
import imgInstagramFooter from '../../assets/img/footer-instagram.png'
import imgLinkedinFooter from '../../assets/img/footer-linkedin.png'
import imgTwitterFooter from '../../assets/img/footer-twitter.png'
import imgYoutubeFooter from '../../assets/img/footer-youtube.png'

function Footer() {
    return (
        <footer className="main-footer">
            <div className="content">
                <div className="text left">
                    <a href="https://grupociatos.com.br/">Grupo Ciatos</a>
                    <a href="/cultura-ciatos">Missão Visão e Valores</a>
                    <button onClick={() => this.showModal('useTermsDisplayControl')}>Termos de Uso</button>
                    <button onClick={() => this.showModal('privacyPolicyDisplayControl')}>Política de Privacidade</button>
                </div>
                <div className="image-wrapper">
                    <img src={imgArvoreGrupoCiatos} alt="Árvore do Grupo Ciatos" />
                </div>
                <div className="text right">
                    <p>Rua Guaicuí, nº 715 Luxemburgo</p>
                    <p>Telefone (31) 4042-0009</p>
                    <p>WhatsApp (31) 99110-0349</p>
                    <p>E-mail: atendimento@ctcw.com.br</p>
                </div>
            </div>
            <div className="divider"></div>

            <div className="bottom">
                <p>©2021 - Ciatos Coworking. Todos os direitos reservados.</p>
                <div className="links">
                    <a target="_blank" rel="noreferrer" href="https://www.facebook.com/GrupoCiatosReestruturacoesEmpresariais/"><img src={imgFacebookFooter} alt="Facebook" /></a>
                    <a target="_blank" rel="noreferrer" href="https://twitter.com/grupociatos"><img src={imgTwitterFooter} alt="Twitter" /></a>
                    <a target="_blank" rel="noreferrer" href="https://www.instagram.com/grupociatos/"><img src={imgInstagramFooter} alt="Instagram" /></a>
                    <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/grupociatos/"><img src={imgLinkedinFooter} alt="Linkedin" /></a>
                    <a target="_blank" rel="noreferrer" href="https://www.youtube.com/channel/UCe-TCC8mOb7GtbvPkZWsfWg"><img src={imgYoutubeFooter} alt="Youtube" /></a>
                </div>
            </div>

        </footer>
    )
}

export default Footer