import React from 'react'
import FeaturedArticles from '../../components/featured-articles/FeaturedArticles'
import Footer from '../../components/footer/Footer'
import Header from '../../components/header/Header'
import ArticleList from '../../components/article-list/ArticleList'
import WelcomeSection from '../../components/welcome-section/WelcomeSection'
import FeaturedLinks from '../../../components/featured-links/FeaturedLinks'
import WhatsappLink from '../../../components/whatsapp-link/WhatsappLink'
import Form from '../../components/form/Form'
import './style.css'

function Blog() {
    return (
        <div id="blog-page" className="home-page">
            <FeaturedLinks />
            <WhatsappLink />
            <Header />
            <WelcomeSection />
            <FeaturedArticles />
            <Form
                title="Fique por dentro!"
                article={{
                    title: '',
                    category: 'Acesso ao blog da Ciatos Coworking (Página Principal)',
                    selfLink: '',
                }} />
            <section className="main-section">
                <h2>Últimas postagens</h2>
                <ArticleList />
            </section>
            <Footer />
        </div>
    )
}

export default Blog