import React, { Component } from 'react'
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import Article from '../../components/article/Article'
import Form from '../../components/form/Form'
import SuggestedArticles from '../../components/suggested-articles/SuggestedArticles'
import { getArticleBySlug } from '../../services/ArticleService'
import WhatsappLink from '../../../components/whatsapp-link/WhatsappLink'
import FeaturedLinks from '../../../components/featured-links/FeaturedLinks'

class Single extends Component {
    constructor() {
        super()
        this.state = {
            article: {}
        }
    }

    async componentDidMount() {
        // console.log('Slug: ', this.props)
        const article = await getArticleBySlug(this.props.match.params.slug)
        // console.log(article)
        this.setState({
            article: article
        })
    }

    render() {
        // console.log(this.state.article)
        return (
            <div className="single">
                <FeaturedLinks />
                <WhatsappLink />
                <Header noTransparent={true} />
                <Article article={this.state.article} />
                <Form article={{
                    title: this.state.article.title,
                    category: this.state.article.categoryName,
                    selfLink: this.state.article.selfLink,
                }} />
                {
                    this.state.article.hasOwnProperty('categories')
                        ? <SuggestedArticles
                            category={this.state.article.categories[0]}
                            articleId={this.state.article.id} />
                        : <div></div>
                }
                <Footer />
            </div>
        )
    }
}

export default Single