import React, { Component } from 'react'
import { getArticle } from '../../services/ArticleService.js'
import './style.css'
import noImage from '../../../assets/img/no-image.png'
import leftArrow from '../../../assets/img/icons/left-arrow.svg'
import rightArrow from '../../../assets/img/icons/right-arrow.svg'

class Carousel extends Component {
    constructor() {
        super()
        this.state = {
            selectedArticle: 0,
            articles: []
        }
        this.articlesPositions = [
            'first',
            'second',
            'third',
        ]
    }

    translateArticles = (param) => {
        const selected = this.state.selectedArticle

        if (param && selected < 2)
            this.setState({ selectedArticle: selected + 1 })

        else if (!param && selected > 0)
            this.setState({ selectedArticle: selected - 1 })
    }

    async componentDidMount() {
        const article1 = await getArticle(6)
        const article2 = await getArticle(702)
        const article3 = await getArticle(11741)

        this.setState({ articles: [article1, article2, article3] })
    }

    render() {
        return (
            <div className="carousel">

                {this.state.articles.map(article => {
                    return (
                        <div key={article.id} className={`article ${this.articlesPositions[this.state.selectedArticle]}`}
                            style={{
                                background: `url(${article.imgUrl ?? noImage})`,
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat'
                            }}>
                            <a target="_blank" rel="noreferrer" href={`http://blog.grupociatos.com.br/article/${article.slug}`}>
                                <div className="shadow">
                                    <p className="title">{article.title}</p>
                                    <p className="info">{`${article.author}  -  ${article.date}`}</p>
                                </div>
                            </a>
                        </div>
                    )
                })}

                <button className="btn-arrow left" onClick={() => this.translateArticles(false)}>
                    <img className="arrow-icon" src={leftArrow} alt="Seta para a esquerda" />
                </button>
                <button className="btn-arrow right" onClick={() => this.translateArticles(true)}>
                    <img className="arrow-icon" src={rightArrow} alt="Seta para a direita" />
                </button>
            </div>
        )
    }
}

export default Carousel