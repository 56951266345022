import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import Home from './pages/home/Home'
import Blog from './blog/pages/home/Blog'
import Category from './blog/pages/category/Category'
import Search from './blog/pages/search/Search'
import Segment from './blog/pages/segment/Segment'
import Single from './blog/pages/single/Single'
import CulturaCiatos from './pages/cultura-ciatos/CulturaCiatos'


function Routes() {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/cultura-ciatos" exact component={CulturaCiatos} />
                <Route path="/blog" exact component={Blog} />
                <Route path="/blog/article/:slug" exact component={Single} />
                <Route path="/blog/categories/:id" component={Category} />
                <Route path="/blog/segments/:id" component={Segment} />
                <Route path="/blog/search/:word" component={Search} />
            </Switch>
        </BrowserRouter>
    )
}

export default Routes