import React from 'react'
import Carousel from '../carousel/Carousel'
import TopArticles from '../top-articles/TopArticles'
import './style.css'

function FeaturedArticles() {
    return (
        <div className="fa">
            <Carousel />
            <TopArticles />
        </div>
    )
}

export default FeaturedArticles