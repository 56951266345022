import { Component } from "react";
import './style.css'

import arrowLeft from '../../assets/img/icons/left-arrow.svg'
import arrowRight from '../../assets/img/icons/right-arrow.svg'
import close from '../../assets/img/icons/close-white.svg'

class Pictures extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selected: 0
        }
    }

    nextImage = () => {
        if (this.state.selected >= (this.props.pictures.length - 1))
            return;

        this.setState(prev => {
            return { selected: prev.selected + 1 }
        })
    }

    previousImage = () => {
        if (this.state.selected <= 0)
            return;

        this.setState(prev => {
            return { selected: prev.selected - 1 }
        })
    }

    render() {
        return (
            <div className="pictures">
                <div className="slider">
                    <div className="image-wrapper">
                        <img src={this.props.pictures[this.state.selected]} alt="Alguma coisa" />
                    </div>

                </div>
                <button className="btn-close" onClick={this.props.close}>
                    <img src={close} alt="Close" />
                </button>
                <button className="btn-slider" onClick={this.previousImage}>
                    <img src={arrowLeft} alt="Seta para esquerda" />
                </button>
                <button className="btn-slider" onClick={this.nextImage}>
                    <img src={arrowRight} alt="Seta para direita" />
                </button>
            </div>
        )
    }
}

export default Pictures