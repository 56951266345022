import React, { Component } from 'react'
import { sendEmail } from '../../services/EmailService.js'
import ClipLoader from "react-spinners/ClipLoader"
import './style.css'

class Form extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            email: '',
            phone: '',
            message: '',
            subject: '',
            result: '',
            resultStyle: '',
            loading: false,
        }
    }

    handleChange = (event) => {

        const { name, value } = event.target

        this.setState({ [name]: value })
    }

    handleSubmit = async (event) => {
        event.preventDefault()

        this.setState({ loading: true })

        const names = this.state.name.split(' ')

        const success = await sendEmail(
            names[0] ?? '', names[1] ?? '',
            this.state.email,
            this.state.phone,
            `Artigo acessado: ${this.props.article.title} \nLink para o artigo: ${this.props.article.selfLink}`,
            this.props.article.category
        )

        console.log(success)

        if (success) {
            this.setState({ result: 'Dados enviados com sucesso!', resultStyle: 'success' })
        } else {
            this.setState({ result: 'Falha ao enviar dados!', resultStyle: 'failed' })
        }

        this.setState({ loading: false })
    }

    render() {
        // console.log(this.props.article.selfLink)
        const loading = this.state.loading ? <ClipLoader color="#000000" loading={true} size={30} /> : ''
        return (
            <div className="blog-form">
                <form onSubmit={this.handleSubmit}>
                    <p className="title">{this.props.title ?? 'Falar com um consultor'}</p>
                    <input
                        type="text"
                        placeholder="Nome"
                        name="name"
                        value={this.state.name}
                        onChange={this.handleChange} />
                    <input
                        type="email"
                        placeholder="Email"
                        name="email"
                        value={this.state.email}
                        onChange={this.handleChange} />
                    <input
                        type="text"
                        placeholder="Telefone"
                        name="phone"
                        value={this.state.phone}
                        onChange={this.handleChange} />
                    <button>Enviar</button>

                    <div className="spinner"> {loading} </div>
                    <div className={`result ${this.state.resultStyle}`}>{this.state.result}</div>
                </form>
            </div>
        )
    }
}

export default Form
