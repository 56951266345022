import React from 'react'
import './style.css'
import facebook from '../../assets/img/icons/facebook-white.svg'
import instagram from '../../assets/img/icons/instagram-white.svg'
import twitter from '../../assets/img/icons/twitter-white.svg'
import whatsapp from '../../assets/img/icons/whatsapp-white.svg'
import linkedin from '../../assets/img/icons/linkedin-white.svg'

function FeaturedLinks() {
    return (
        <div className="featured-links">
            <a target="_blank" rel="noreferrer" href="https://www.facebook.com/CiatosCoworking/">
                <div className="social-link first">
                    <img src={facebook} alt="Facebook" />
                </div>
            </a>
            <a target="_blank" rel="noreferrer" href="https://www.instagram.com/ciatoscoworking/">
                <div className="social-link">
                    <img src={instagram} alt="Instagram" />
                </div>
            </a>
            <a target="_blank" rel="noreferrer" href="https://twitter.com/grupociatos">
                <div className="social-link">
                    <img src={twitter} alt="Twitter" />
                </div>
            </a>
            <a target="_blank" rel="noreferrer" href="https://api.whatsapp.com/send?phone=+5531991100349&text=ola">
                <div className="social-link">
                    <img src={whatsapp} alt="Whatsapp" />
                </div>
            </a>
            <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/grupociatos/">
                <div className="social-link last">
                    <img src={linkedin} alt="LinkedIn" />
                </div>
            </a>

            <div className="featured-email">
                atendimento@ctcw.com.br | (31) 4042-0009
            </div>
        </div>
    )
}

export default FeaturedLinks