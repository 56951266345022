import React, { Component } from 'react'
import SearchField from "../search-field/SearchField";

import './style.css'

import logo from '../../../assets/img/logo-ciatos-coworking.png'
import logoWhite from '../../../assets/img/logo-white.png'
import MenuMobile from '../menu-mobile/MenuMobile'
import { getSegments } from '../../services/SegmentService'
import { getCategories } from '../../services/CategoryService'

class Header extends Component {

    constructor() {
        super()
        this.state = {
            category: 'hide',
            segment: 'hide',
            ciatos: 'hide',
            segments: [],
            categories: [],
            background: 'background-transparent',
            logo: logoWhite,
        }
    }

    async componentDidMount() {
        window.addEventListener('scroll', this.listenToScroll)
        const segments = await getSegments()
        const categories = await getCategories()
        this.setState({
            segments: segments,
            categories: categories,
        })
    }

    listenToScroll = () => {
        const windowScrollPosition = window.pageYOffset

        if (windowScrollPosition > 100) {
            this.setState({
                background: 'background-white',
                logo: logo
            })
        } else {
            this.setState({
                background: 'background-transparent',
                logo: logoWhite
            })
        }
    }

    toggleDropdown = (param) => {
        const p = this.state[param]
        this.setState({
            [param]: p ? '' : 'hide'
        })
    }

    render() {
        const logoImg = this.props.noTransparent ? logo : this.state.logo
        return (
            <header id="blog-header" className={`header ${this.state.background}`}>
                <div className="header-content">
                    <a href="/">
                        <img className="logo" src={logoImg} alt="Logo do Ciatos Coworking" />
                    </a>

                    <nav className="navbar">
                        <a className="nav-item" href="/blog/">Home</a>
                        <div
                            className="nav-item nav-dropdown"
                            onMouseEnter={() => this.toggleDropdown('category')}
                            onMouseLeave={() => this.toggleDropdown('category')}>
                            Categorias
                            <div className={`dropdown ${this.state.category}`} >
                                {
                                    this.state.categories.map(c => {
                                        return <a className="dropdown-link" key={c.id} href={`/blog/categories/${c.id}`}>{c.name}</a>
                                    })
                                }
                            </div>
                        </div>
                        <div
                            className="nav-item nav-dropdown"
                            onMouseEnter={() => this.toggleDropdown('segment')}
                            onMouseLeave={() => this.toggleDropdown('segment')}>
                            Segmentos
                            <div className={`dropdown ${this.state.segment}`}>
                                {/* <a className="dropdown-link" href="/segments/108">Recuperação judicial</a> */}
                                {
                                    this.state.segments.map(s => {
                                        return <a className="dropdown-link" key={s.id} href={`/blog/segments/${s.id}`}>{s.name}</a>
                                    })
                                }
                            </div>
                        </div>
                        <div
                            className="nav-item nav-dropdown"
                            onMouseEnter={() => this.toggleDropdown('ciatos')}
                            onMouseLeave={() => this.toggleDropdown('ciatos')}>
                            Grupo Ciatos
                            <div className={`dropdown ${this.state.ciatos}`}>
                                <a className="dropdown-link" href="https://grupociatos.com.br/">O Grupo Ciatos</a>
                                <a className="dropdown-link" href="https://grupociatos.com.br/juridicoEmpresarial.php">Jurídico Empresarial</a>
                                <a className="dropdown-link" href="https://grupociatos.com.br/contabilidade.php">Ciatos Contabilidade</a>
                                <a className="dropdown-link" href="https://grupociatos.com.br/coworking.php">Ciatos Coworking</a>
                                <a className="dropdown-link" href="https://grupociatos.com.br/planejamentoTributario.php">Planejamento Tributário</a>
                                <a className="dropdown-link" href="https://grupociatos.com.br/planejamentoFamiliar.php">Planejamento Familiar</a>
                            </div>
                        </div>
                    </nav>

                    <SearchField background={this.state.background} />

                    <MenuMobile segments={this.state.segments} categories={this.state.categories} />
                </div>
            </header>
        )
    }
}

export default Header