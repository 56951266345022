import React, { Component } from 'react'
import ArticleCard from '../article-card/ArticleCard'
import { getArticles, getArticlesByCategoryId, getArticlesBySegmentId, searchArticles } from '../../services/ArticleService.js'
import './style.css'

class ArticleList extends Component {
    constructor() {
        super()
        this.state = {
            articles: [],
            isLoading: true,
        }
    }

    async componentDidMount() {
        let articles = []

        if (this.props.categoryId) {
            articles = await getArticlesByCategoryId(this.props.categoryId)

        } else if (this.props.segmentId) {
            articles = await getArticlesBySegmentId(this.props.segmentId)

        } else if (this.props.search) {
            articles = await searchArticles(this.props.search)

        } else {
            articles = await getArticles()
        }

        this.setState({
            articles: articles,
            isLoading: false,
        })
    }

    render() {
        if (this.state.isLoading) {
            return <h2 className="loading">Carregando artigos...</h2>
        }
        if (this.state.articles.length === 0) {
            return <h2 className="loading">Nenhum artigo encontrado.</h2>
        }
        return (
            <div className="latest">
                <div className="article-list">
                    {this.state.articles.map(article => {
                        return (
                            <React.Fragment key={article.id}>
                                <ArticleCard article={article} />
                            </React.Fragment>
                        )
                    })}
                </div>
            </div>
        )
    }
}

export default ArticleList