import React, { Component } from 'react'

import Header from '../../components/header/Header'
import Modal from '../../components/modal/Modal'
import FeaturedLinks from '../../components/featured-links/FeaturedLinks'
import UseTerms from '../../components/use-terms/UseTerms'
import PrivacyPolicy from '../../components/privacy-policy/PrivacyPolicy'
import Ambientes from '../../components/ambientes/Ambientes'
import Section10 from '../../components/section10/Section10'
import Footer from '../../components/footer/Footer'
import WhatsappLink from '../../components/whatsapp-link/WhatsappLink'

import './style.css'

import imgSection2 from '../../assets/img/section2.jpg'
import imgMapSection3 from '../../assets/img/mapa-section3.png'
import imgJuriSection5 from '../../assets/img/section5-juri.png'
import imgRecrutamentoSection5 from '../../assets/img/section5-recrutament.png'
import imgConsultoriaSection5 from '../../assets/img/section5-consultando.png'
import imgPsicologoSection5 from '../../assets/img/section5-psicologo.png'
import imgTributosSection5 from '../../assets/img/section5-tributos.png'
import imgContabilidadeSection5 from '../../assets/img/section5-contabilidade.png'

import imgOfficeSection4 from '../../assets/img/section4-office.png'
import imgConsultandoSection4 from '../../assets/img/section4-consultando.png'
import imgSalasPrivativasSection4 from '../../assets/img/section4-salas-privativas.png'
import imgAuditorioSection4 from '../../assets/img/section4-auditorio.png'
import imgCafeSection4 from '../../assets/img/section4-cafe.png'
import imgMeetingSection4 from '../../assets/img/section4-meeting.png'
import imgSharedTablesSection4 from '../../assets/img/section4-shared-tables.png'
import imgFacilitiesSection4 from '../../assets/img/section4-facilities.png'

import imgChartSection6 from '../../assets/img/section6-chart.png'
import imgEnderecoSection6 from '../../assets/img/section6-endereco.png'
import imgMailSection6 from '../../assets/img/section6-mail.png'
import imgPlataformaSection6 from '../../assets/img/section6-plataforma.png'
import imgPlataformaWhiteSection6 from '../../assets/img/section6-plataforma-white.png'
import imgGroupSection6 from '../../assets/img/section6-group.png'
import imgWorkSection6 from '../../assets/img/section6-work.png'
import imgInternetSection6 from '../../assets/img/section6-internet.png'
import imgLockSection6 from '../../assets/img/section6-lock.png'
import imgPhoneSection6 from '../../assets/img/section6-phone.png'
import imgScheduleSection6 from '../../assets/img/section6-schedule.png'
import imgPhoneWhiteSection6 from '../../assets/img/section6-phone-white.png'
import imgAtendimentoSection6 from '../../assets/img/section6-atendimento.png'
import imgMiniAuditorioSection6 from '../../assets/img/section6-mini-auditorio.png'
import imgMeetingRoomSection6 from '../../assets/img/section6-meeting-room.png'



class Home extends Component {

    constructor() {
        super()

        this.state = {
            modalDisplayControl: 'hide',
            useTermsDisplayControl: 'hide',
            privacyPolicyDisplayControl: 'hide',
            selectedImageSet: 'auditorio',
        }

        this.showModal = this.showModal.bind(this)
        this.hideModal = this.hideModal.bind(this)
        this.setImageSet = this.setImageSet.bind(this)
    }

    showModal(param) {
        this.setState({ [param]: '' })
    }

    hideModal(param) {
        this.setState({ [param]: 'hide' })
    }

    setImageSet(param) {
        this.setState({ selectedImageSet: param })
    }

    render() {

        return (
            <main id="home-page" className="home-page">

                <FeaturedLinks />
                <WhatsappLink />
                <div className={`${this.state.useTermsDisplayControl}`}>
                    <UseTerms close={this.hideModal} />
                </div>
                <div className={`${this.state.privacyPolicyDisplayControl}`}>
                    <PrivacyPolicy close={this.hideModal} />
                </div>

                <div className={`modal-wrapper ${this.state.modalDisplayControl}`}>
                    <Modal closeModal={this.hideModal} />
                </div>

                <section id="ctcw" className="section1">
                    <Header />
                    <div className="content-wrapper">
                        <div className="content">
                            <h1>CIATOS COWORKING, A SOLUÇÃO PARA SUA EMPRESA!</h1>
                            <p>Na Ciatos Coworking Belo Horizonte você tem, além de infraestrutura completa de um Coworking, diversos serviços com custo reduzido.</p>
                            <div className="buttons">
                                <button className="btn" onClick={() => this.showModal('modalDisplayControl')}>Solicite um contato</button>
                                <a className="btn" href="https://admin.grupociatos.com.br/" rel="noreferrer" target="_blank">Sou Cliente CW</a>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="ciatos-coworking" className="section2">
                    <div className="content">
                        <h2>CIATOS COWORKING</h2>
                        <div className="flex-container">
                            <div className="image-wrapper">
                                <img src={imgSection2} alt="Ambiente de coworking discontraído." />
                            </div>
                            <p>A Ciatos Coworking Belo Horizonte é uma mistura de ambiente corporativo com algumas
                            áreas de descompressão. Na Ciatos Coworking Belo Horizonte você tem a sua disposição
                            um escritório, privativo ou compartilhado, para quem deseja focar no seu trabalho sem
                            se preocupar com a administração ou com o alto custo de um escritório.
                            <br /> <br />
                            O CTCW é um escritório compartilhado em Belo Horizonte, para quem deseja focar no seu
                            trabalho sem se preocupar com a administração ou com o alto custo de um escritório.
                            <br /> <br />
                            Nosso espaço é ideal para funcionamento de pequenas empresas, startus ou profissionais
                            autônomos desempenharem suas atividades.
                            <br /> <br />
                            Além de oferecermos uma infraestrutura completa, com salas de reunião, escritórios
                            privativos e estações de trabalho compartilhadas, para realização do seu trabalho com
                            qualidade e tranquilidade, nosso espaço te proporcina vivenciar um ambiente estimulante
                            e harmonizado com áreas de descompressão e ambientes abertos, unindo economia, bom gosto
                            e praticidade em um só lugar!
                        </p>
                        </div>
                    </div>
                </section>

                <section id="onde-estamos-localizados" className="section3">
                    <div className="content">
                        <h2>ONDE ESTAMOS LOCALIZADOS?</h2>
                        <p>A Ciatos Coworking Belo Horizonte é um coworking localizado na região nobre de Belo Horizonte,
                        no Bairro Luxemburgo, na Rua Guaicuí,  nº 715. Visando melhor atender as expectativas e a qualidade
                        de vida dos seus clientes, esta localizada próximo de centros comerciais, academias e supermercados,
                        em local privilegiado, com fácil acesso, pois está próximas das avenidas Raja Gabaglia e Prudente de Morais.
                        </p>
                        <a target="_blank" rel="noreferrer" href="https://www.google.com/maps/place/CTCW+-+Ciatos+Coworking+%7C+Luxemburgo+Coworking+Belo+Horizonte/@-19.947119,-43.9550134,16z/data=!4m5!3m4!1s0xa6979c80c66151:0xe296ba40469e40da!8m2!3d-19.9476611!4d-43.9555364">
                            <img src={imgMapSection3} alt="Screenshot do google maps." />
                        </a>
                    </div>
                </section>

                <section id="servicos-da-ciatos-coworking" className="section4">
                    <div className="content">
                        <h2>QUAIS OS SERVIÇOS DO CIATOS COWORKING BELO HORIZONTE?</h2>
                        <p>Temos em um só lugar tudo o que é preciso para proporcionar o ambiente de trabalho ideal:</p>
                        <div className="flex-container">
                            <div className="flex-item">
                                <div className="card-content">
                                    <img src={imgOfficeSection4} alt="Escritório Virtual" />
                                    <p className="title">Escritótrio Virtual</p>
                                    <p className="body">Endereço Fiscal, Comercial e  gestão de correspondência</p>
                                </div>
                            </div>
                            <div className="flex-item">
                                <div className="card-content">
                                    <img src={imgConsultandoSection4} alt="Serviços de Recepção" />
                                    <p className="title">Serviços de Recepção</p>
                                    <p className="body">Atendimento e número de telefone personalizado, endereço fiscal e comercial</p>
                                </div>
                            </div>
                            <div className="flex-item">
                                <div className="card-content">
                                    <img src={imgSalasPrivativasSection4} alt="Salas Privativas" />
                                    <p className="title">Salas Privativas</p>
                                    <p className="body">Salas Privativas, com infraestrutura completa para 4, 5, 6 ou 8 pessoas</p>
                                </div>
                            </div>
                            <div className="flex-item">
                                <div className="card-content">
                                    <img src={imgAuditorioSection4} alt="Auditório" />
                                    <p className="title">Auditório</p>
                                    <p className="body">Pequeno Auditório para até 15 pessoas</p>
                                </div>
                            </div>
                            <div className="flex-item">
                                <div className="card-content">
                                    <img src={imgCafeSection4} alt="Áreas de Convivência" />
                                    <p className="title">Áreas de Convivência</p>
                                    <p className="body">Áreas de descompressão, que também pode ser utilizadas com base de trabalho.</p>
                                </div>
                            </div>
                            <div className="flex-item">
                                <div className="card-content">
                                    <img src={imgMeetingSection4} alt="Salas de Reuniões" />
                                    <p className="title">Salas de Reuniões</p>
                                    <p className="body">Salas de Reuniões com toda infraestrutura para atender os clientes</p>
                                </div>
                            </div>
                            <div className="flex-item">
                                <div className="card-content">
                                    <img src={imgSharedTablesSection4} alt="Mesas Compartilhadas" />
                                    <p className="title">Mesas Compartilhadas</p>
                                    <p className="body">Espaço compartilhada com base individuais para atender o Cliente</p>
                                </div>
                            </div>

                            <div className="flex-item">
                                <div className="card-content">
                                    <img src={imgFacilitiesSection4} alt="Facilidades" />
                                    <p className="title">Facilidades</p>
                                    <p className="body">Contabilidade, Jurídico, Consultoria e Recrutamento.</p>
                                </div>
                            </div>
                        </div>
                        <p>E o principal, focamos no seu negócio, visando assessorá-lo a maximizar os resultados do seu negócio!</p>
                        <div className="btn-wrapper">
                            <button className="btn" onClick={() => this.showModal('modalDisplayControl')}>Fale com Consultor</button>
                        </div>
                    </div>
                </section>

                <section id="facilities" className="section5">
                    <div className="content">
                        <h2>NOSSAS FACILITIES</h2>
                        <p>A Ciatos Coworking Belo Horizonte, além de proporcinar uma experiência incrível aos Clientes, através de um ideal
                        espaço de trabalho, conta com diversas facilidades, com preços reduzidos:
                    </p>
                        <div className="flex-container">
                            <div className="flex-item">
                                <div className="card-content">
                                    <p className="title">Consultas Jurídicas</p>
                                    <p className="body">Advogados Especilistas para assessoria Jurídica por hora, para você e sua empresa</p>
                                    <img src={imgJuriSection5} alt="consultas jurídicas" />
                                </div>
                            </div>
                            <div className="flex-item">
                                <div className="card-content">
                                    <p className="title">Recrutamento e Seleção</p>
                                    <p className="body">Equipe de Recrutamento e Seleção para encontrar o colaborador ideal da sua empresa</p>
                                    <img src={imgRecrutamentoSection5} alt="consultas jurídicas" />
                                </div>
                            </div>
                            <div className="flex-item">
                                <div className="card-content">
                                    <p className="title">Consultoria P5E</p>
                                    <p className="body">Consultores para assessorá-los na gestão da sua empresa</p>
                                    <img src={imgConsultoriaSection5} alt="consultas jurídicas" />
                                </div>
                            </div>
                            <div className="flex-item">
                                <div className="card-content">
                                    <p className="title">Psicólogos</p>
                                    <p className="body">Psicólogos para ajudá-lo a aumentar sua produtividade no dia-a-dia</p>
                                    <img src={imgPsicologoSection5} alt="consultas jurídicas" />
                                </div>
                            </div>
                            <div className="flex-item">
                                <div className="card-content">
                                    <p className="title">Planejamento Tributário</p>
                                    <p className="body">Planejamento para reduzir os tributos da sua empresa.</p>
                                    <img src={imgTributosSection5} alt="consultas jurídicas" />
                                </div>
                            </div>
                            <div className="flex-item">
                                <div className="card-content">
                                    <p className="title">Contabilidade</p>
                                    <p className="body">Profissionais capacitados para prestar serviços de contabilidade para sua empresa.</p>
                                    <img src={imgContabilidadeSection5} alt="consultas jurídicas" />
                                </div>
                            </div>
                        </div>
                        <p>Nosso propósito é assessorar sua empresa e apresentar as melhores soluções.</p>
                        <div className="btn-wrapper">
                            <button className="btn" onClick={() => this.showModal('modalDisplayControl')}>Fale com Consultor</button>
                        </div>
                    </div>
                </section>

                <section id="planos-e-precos-da-ciatos-coworking" className="section6">
                    <div className="content">
                        <h2>QUAIS SÃO OS PLANOS E PREÇOS DA CIATOS COWORKING?</h2>
                        <div className="flex-container">

                            <div className="flex-item">
                                <div className="card">
                                    <p className="title">Escritório Virtual</p>
                                    <p className="price">
                                        <span className="price-1">R$</span>
                                        <span className="price-2">109,90</span>
                                        <span className="price-3">/mês</span>
                                    </p>
                                    <ul>
                                        <li>
                                            <div className="icon-wrapper">
                                                <img src={imgChartSection6} alt="Endereço Fiscal" />
                                            </div>
                                        Endereço Fiscal
                                    </li>
                                        <li>
                                            <div className="icon-wrapper">
                                                <img src={imgEnderecoSection6} alt="Endereço Comercial" />
                                            </div>
                                        Endereço Comercial
                                    </li>
                                        <li>
                                            <div className="icon-wrapper">
                                                <img src={imgMailSection6} alt="Gestão de correspondência" />
                                            </div>
                                        Gestão de Correspondência
                                    </li>
                                        <li>
                                            <div className="icon-wrapper">
                                                <img src={imgPlataformaSection6} alt="Plataforma Ciatos" />
                                            </div>
                                        Plataforma Ciatos
                                    </li>
                                    </ul>
                                    <button className="btn" onClick={() => this.showModal('modalDisplayControl')}>Fale com o consultor</button>
                                </div>
                            </div>

                            <div className="flex-item">
                                <div className="card dark-bg">
                                    <p className="title">Salas Privativas</p>
                                    <p className="price">
                                        <span className="price-1">R$</span>
                                        <span className="price-2">499,00</span>
                                        <span className="price-3">/por pessoa</span>
                                    </p>
                                    <ul>
                                        <li>
                                            <div className="icon-wrapper">
                                                <img src={imgGroupSection6} alt="Sala para 4 pessoas" />
                                            </div>
                                        Sala para 4 pessoas
                                    </li>
                                        <li>
                                            <div className="icon-wrapper">
                                                <img src={imgGroupSection6} alt="Sala para 6 pessoas" />
                                            </div>
                                        Sala para 6 pessoas
                                    </li>
                                        <li>
                                            <div className="icon-wrapper">
                                                <img src={imgGroupSection6} alt="Sala para 8 pessoas" />
                                            </div>
                                        Sala para 8 pessoas
                                    </li>
                                        <li>
                                            <div className="icon-wrapper">
                                                <img src={imgPlataformaWhiteSection6} alt="Plataforma Ciatos" />
                                            </div>
                                        Plataforma Ciatos
                                    </li>
                                    </ul>
                                    <button className="btn" onClick={() => this.showModal('modalDisplayControl')}>Fale com o consultor</button>
                                </div>
                            </div>

                            <div className="flex-item">
                                <div className="card">
                                    <p className="title">Salas Compartilhadas</p>
                                    <p className="price">
                                        <span className="price-1">R$</span>
                                        <span className="price-2">499,00</span>
                                        <span className="price-3">/mês</span>
                                    </p>
                                    <ul>
                                        <li>
                                            <div className="icon-wrapper">
                                                <img src={imgWorkSection6} alt="Estação de Trabalho" />
                                            </div>
                                        Estação de Trabalho
                                    </li>
                                        <li>
                                            <div className="icon-wrapper">
                                                <img src={imgInternetSection6} alt="Internet" />
                                            </div>
                                        Internet
                                    </li>
                                        <li>
                                            <div className="icon-wrapper">
                                                <img src={imgLockSection6} alt="Uso do Locker" />
                                            </div>
                                        Uso do Locker
                                    </li>
                                        <li>
                                            <div className="icon-wrapper">
                                                <img src={imgPlataformaSection6} alt="Plataforma Ciatos" />
                                            </div>
                                        Plataforma Ciatos
                                    </li>
                                    </ul>
                                    <button className="btn" onClick={() => this.showModal('modalDisplayControl')}>Fale com o consultor</button>
                                </div>
                            </div>

                            <div className="flex-item">
                                <div className="card">
                                    <p className="title">Gestão de Agenda</p>
                                    <p className="price">
                                        <span className="price-1">R$</span>
                                        <span className="price-2">499,00</span>
                                        <span className="price-3">/mês</span>
                                    </p>
                                    <ul>
                                        <li>
                                            <div className="icon-wrapper">
                                                <img src={imgPhoneSection6} alt="Telefone Personalizado" />
                                            </div>
                                        Telefone Personalizado
                                    </li>
                                        <li>
                                            <div className="icon-wrapper">
                                                <img src={imgScheduleSection6} alt="Gestão de Agenda" />
                                            </div>
                                        Gestão de Agenda
                                    </li>
                                        <li>
                                            <div className="icon-wrapper">
                                                <img src={imgPlataformaSection6} alt="Plataforma Ciatos" />
                                            </div>
                                        Plataforma Ciatos
                                    </li>
                                    </ul>
                                    <button className="btn" onClick={() => this.showModal('modalDisplayControl')}>Fale com o consultor</button>
                                </div>
                            </div>

                            <div className="flex-item">
                                <div className="card dark-bg">
                                    <p className="title">Telefonia e Atendimento</p>
                                    <p className="price">
                                        <span className="price-1">R$</span>
                                        <span className="price-2">259,00</span>
                                        <span className="price-3">/mês</span>
                                    </p>
                                    <ul>
                                        <li>
                                            <div className="icon-wrapper">
                                                <img src={imgPhoneWhiteSection6} alt="Telefone Personalizado" />
                                            </div>
                                        Telefone Personalizado
                                    </li>
                                        <li>
                                            <div className="icon-wrapper">
                                                <img src={imgAtendimentoSection6} alt="Atendimento Personalizado" />
                                            </div>
                                        Atendimento Personalizado
                                    </li>
                                        <li>
                                            <div className="icon-wrapper">
                                                <img src={imgPlataformaWhiteSection6} alt="Plataforma Ciatos" />
                                            </div>
                                        Plataforma Ciatos
                                    </li>
                                    </ul>
                                    <button className="btn" onClick={() => this.showModal('modalDisplayControl')}>Fale com o consultor</button>
                                </div>
                            </div>

                            <div className="flex-item">
                                <div className="card">
                                    <p className="title">Sala de Reunião/Auditório</p>
                                    <p className="price">
                                        <span className="price-1">R$</span>
                                        <span className="price-2">65,00 a 150,00</span>
                                        <span className="price-3">/hora</span>
                                    </p>
                                    <ul>
                                        <li>
                                            <div className="icon-wrapper">
                                                <img src={imgMiniAuditorioSection6} alt="Mini-auditório" />
                                            </div>
                                        Mini-auditório
                                    </li>
                                        <li>
                                            <div className="icon-wrapper">
                                                <img src={imgMeetingRoomSection6} alt="Salas de Reunião" />
                                            </div>
                                        Salas de Reunião
                                    </li>
                                        <li>
                                            <div className="icon-wrapper">
                                                <img src={imgPlataformaSection6} alt="Plataforma Ciatos" />
                                            </div>
                                        Plataforma Ciatos
                                    </li>
                                    </ul>
                                    <button className="btn" onClick={() => this.showModal('modalDisplayControl')}>Fale com o consultor</button>
                                </div>
                            </div>

                        </div>

                        <p>Clientes do Ciatos Coworking terão descontos na CafeWorking.</p>
                    </div>
                </section>

                <section id="conheca-nossos-espacos" className="section7">
                    <h2>CONHEÇA NOSSOS ESPAÇOS</h2>
                    <Ambientes />
                </section>

                <section id="preco-das-facilidades" className="section8">
                    <div className="content">
                        <h2>QUAIS OS PREÇOS DAS FACILIDADES?</h2>
                        <div className="wrapper">
                            <div className="flex-container">

                                <div className="flex-item">
                                    <div className="card">
                                        <p className="title">Contabilidade</p>
                                        <p className="price">
                                            <span className="price-1">R$</span>
                                            <span className="price-2">249,00</span>
                                            <span className="price-3">/mês</span>
                                        </p>
                                        <ul>
                                            <li>Simples Nacional</li>
                                            <li>Até (3) Funcionários</li>
                                            <li>100 Notas Fiscais</li>
                                            <li>Software's Ciatos</li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="flex-item">
                                    <div className="card">
                                        <p className="title">Jurídico</p>
                                        <p className="price">
                                            <span className="price-1">R$</span>
                                            <span className="price-2">99,00</span>
                                            <span className="price-3">/mês</span>
                                        </p>
                                        <ul>
                                            <li>Consulta Jurídica</li>
                                            <li>Análise de Contrato</li>
                                            <li>Pareceres</li>
                                            <li>Software's Ciatos</li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="flex-item">
                                    <div className="card">
                                        <p className="title">Consultoria</p>
                                        <p className="price">
                                            <span className="price-1">R$</span>
                                            <span className="price-2">99,00</span>
                                            <span className="price-3">/mês</span>
                                        </p>
                                        <ul>
                                            <li>Financeiro</li>
                                            <li>Pessoas</li>
                                            <li>Tributos</li>
                                            <li>Software's Ciatos</li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="flex-item">
                                    <div className="card">
                                        <p className="title">Recrutamento e Seleção</p>
                                        <p className="price">
                                            <span className="price-1"></span>
                                            <span className="price-2">50%</span>
                                            <span className="price-3">do salário</span>
                                        </p>
                                        <ul>
                                            <li>Definimos Perfil</li>
                                            <li>Recrutamos</li>
                                            <li>Selecionamos</li>
                                            <li>Software's Ciatos</li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                            <div className="img"></div>
                        </div>
                    </div>
                </section>

                <Section10 showModal={() => this.showModal('modalDisplayControl')} />

                <Footer />

            </main>
        )
    }
}

export default Home